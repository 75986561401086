import "@/components/Button/button.scss";
import Col from "@/components/Col/Col";
import Footer from "@/components/Footer/Footer";
import Header from "@/components/Header/Header";
import Section from "@/components/Section/Section";
import Title from "@/components/Title/Title";
import * as React from "react";
import "../../App.scss";

const PrivacyPolicy = () => (
  <main>
    <Header alwaysFixed></Header>
    <div className="spacerHeaderFixed"></div>

    <Section className="PrivacyPolicy Textpage" colFlex>
      <Col size="70">
        <Title
          maintitle="Privacy Policy"
          subtitle="Last Update: September 9th, 2021"></Title>

        <h4>Introduction and Scope</h4>

        <p>
          This privacy policy (“Privacy Policy”) describes the collection of
          personal information by Ecomtrack s.r.o., (“Company,” “we,” “us”, or
          “our”) from users (“you”, “your”) of our website at www.ecomtrack.io
          (our “Site”) along with our related websites, networks, applications,
          platforms, and other services provided by us and on which a link to
          this Privacy Policy is displayed (collectively, our “Service” or
          “Services”). This Privacy Policy also describes our use and disclosure
          of such information.
        </p>

        <p>
          This Privacy Policy also describes your rights as a data subject to
          inquire about your personal information that we process and describes
          certain rights that you, as the data subject, have regarding this
          information.
        </p>

        <p>
          For the purposes of EU and United Kingdom data protection laws ("Data
          Protection Legislation"), Ecomtrack s.r.o. is a data controller (i.e.,
          the company that is responsible for and controls the processing of
          your personal information).
        </p>

        <p>
          Please read this Privacy Policy carefully to understand our practices
          regarding your personal information and how we will use it. By
          accepting this Privacy Policy and Terms and Conditions, you agree to
          our collection, storage, use and disclosure of your personal
          information as described in this Privacy Policy.
        </p>

        <h4>Contacting Us</h4>

        <p>
          If you have any questions or comments about this Privacy Policy,
          please contact us using the following contact information:
        </p>

        <span>Ecomtrack s.ro.</span>
        <span>K Pasekám 2984/45 76001 Zlín</span>
        <span className="mb20">Czech Republic</span>

        <h4>What information do we collect?</h4>

        <p>
          We use your personal information to carry out the obligations arising
          from providing and improving the Service. This section describes the
          types and categories of personal information we may collect, and how
          we may use that information.
        </p>

        <h3>Information you provide us directly</h3>

        <p>
          We collect personal information that you provide when you sign up,
          enter your information into online forms, or otherwise use the Site
          and the Services. We use this personal information in a variety of
          ways, and this personal information includes the following:
        </p>

        <p>
          When you create an account on the Service we collect your first name,
          last name, and email address;
        </p>

        <p>
          When you order or use the Services, we will collect the information
          necessary to complete billing transactions, including your first name,
          last name, credit card information, and a billing address, which may
          be your personal address or the address of the company you are
          associated with;
        </p>

        <p>
          There may be times where certain features and functionality of the
          Service are invite-only, and as a result, we may collect your first
          name, last name, email address, the URL of your company or entity you
          are associated with, links to social media accounts so we may contact
          you that way, and a mobile phone number we may send scheduling
          reminders to. We may also collect the email addresses of contacts
          associated with you that you would like to include in these
          invitations.
        </p>

        <p>
          If you sign up for our mailing lists, provide feedback, or otherwise
          communicate with us, we may collect contact and business information
          from you, such as your first and last name, e-mail address, mailing
          address, one or more phone numbers and the company name with which you
          are associated with;
        </p>

        <p>
          We also collect other types of personal information that you provide
          to us voluntarily, such as if you contact us via the Site or Service
          regarding customer support or if you ask other questions regarding the
          Services.
        </p>

        <h3>Automatically collected information</h3>

        <p>
          When you use our Services, some information is collected automatically
          and is not provided directly by you. For example, when you access our
          Service, we automatically collect your browser’s Internet Protocol
          (IP) address, your browser type, the nature of the device from which
          you are visiting the Service (e.g., a personal computer or a mobile
          device), the identifier for any handheld or mobile device that you may
          be using, the web site that you visited immediately prior to accessing
          our Service, the actions you take on our Service, and the content,
          features, and activities that you access and participate in on our
          Service. We also may collect information regarding your interaction
          with email messages, such as whether you opened, clicked on, or
          forwarded a message.
        </p>

        <p>
          We may collect this information passively in our server logs. We may
          also collect information passively using technologies such as cookies
          and clear GIFs (also known as “Web beacons”) as described in the
          section ‘Cookies and Similar Technologies’ in this Privacy Policy. We
          use passively-collected information to administer, operate,
          personalize, and improve our Service and systems, to improve the
          effectiveness of advertising on our Service, and to provide
          advertisements and other content that is tailored to you. If we link
          or associate any information gathered through passive means with
          personal information, or if applicable laws require us to treat any
          information gathered through passive means as personal information, we
          treat the combined information as personal information under this
          Privacy Policy. Otherwise, we use and disclose information collected
          by passive means in aggregate form or otherwise in a non-personally
          identifiable form.
        </p>

        <p>
          Our Services may collect information about your use of third-party
          websites that have enabled integrations with our Services, for
          example, whether you have clicked on an advertisement, the website
          address of the site that published this advertisement, and other
          associated information. This personal information may be combined with
          other information obtained from advertising networks and other sources
          to create more accurate data about your viewing and click-through
          activity with these advertisements. If you believe your personal
          information has been used in this manner, you should consult the
          privacy notices posted on the third-party sites for more information
          about the use of your personal information and your associated rights.
        </p>

        <h3>Information collected from you about others</h3>

        <p>
          If you decide to invite a third-party to create an account, receive an
          invitation for a demo, or otherwise interact with the Service, we will
          collect the third-party’s email addresses in order to send an
          invitational email to, and follow up with, the third-party. You should
          ensure that you know the third-party and they are expecting this
          invitation. In any case, you and/or the third party may contact us at
          support@ecomtrack.io to request the removal of this information from
          our databases.
        </p>

        <h4>Cookies and simmilar technologies</h4>

        <p>
          The Service uses cookies and similar technologies to distinguish you
          from other users of the Service. This enables us to provide you with
          the service specifically linked to your user profile and is required
          for the Service to function. Cookies are small files that allow for
          personalization of the Service experience by saving your information
          such as user ID and other preferences. The service also uses a related
          technology called local storage which allows preferences and session
          information to be stored locally on your computer or mobile device.
        </p>

        <p>
          Also, please be aware that third parties, such as companies displaying
          advertisements on the Service or the sites or services provided by
          third parties (“Third-Party Sites”) that may be linked to from the
          Service, may set cookies or use other means of passively collecting
          information about your use of their services, Third-Party Sites or
          content. We do not have access to, or control over, these third-party
          means of passive data collection.
        </p>

        <h4>Collection and processing of sensitive information</h4>

        <p>
          The Service does not collect or process ‘sensitive information’
          defined as data consisting of racial or ethnic origin, political
          opinions, religious or philosophical beliefs, or trade union
          membership, genetic data, biometric data, data concerning health or
          data concerning a natural person's sex ife or sexual orientation. As
          such, you should not provide any such information as part of your user
          profile, as part of any correspondence, or by any other means with
          your use of the Service.
        </p>

        <h4>How do we use personal information?</h4>

        <p>
          We collect personal information when you create an account with the
          Services, when you update your account information, or otherwise
          correspond with us. We use your personal information for the following
          purposes:
        </p>

        <p>
          To facilitate the creation of your account with the Service, to secure
          and personalize your interaction with the Service, and to provide the
          Services you have requested;
        </p>

        <p>
          To send you administrative email notifications, such as security or
          support and maintenance advisories;
        </p>

        <p>
          We may also use the personal information you provide to contact you
          regarding your use of the Service or to solicit feedback;
        </p>

        <p>
          When you communicate with us using one of the methods described in
          this Privacy Policy, we may also keep a record of the time and date of
          any correspondence, and also organize this correspondence in one or
          more of an electronic filing system, an email system or a customer
          relationship management system;
        </p>

        <p>
          We link this personal information to data about the way you use our
          Service and the pages you visit to help enhance, improve, operate, and
          maintain our Service, our platforms, websites, and other systems;
        </p>

        <p>To prevent fraudulent use of our Service and other systems;</p>

        <p>
          To prevent or take action against activities that are, or may be, in
          violation of our Terms and Conditions or applicable law;
        </p>

        <p>
          We may also use the personal information you provide for direct
          marketing of our Services to you. We allow you to opt-out from
          receiving marketing communications from us as described in the
          “Communication choices” section below, and also at the time you sign
          up and create an account with the Service. Even if you opt-out, we may
          continue to send you administrative emails, including, for example,
          periodic updates to this Privacy Policy;
        </p>

        <p>
          To display personalized or targeted content to you, and to display
          targeted advertising on third party websites;
        </p>

        <p>
          For internal product development purposes to develop new products and
          services, and to improve existing ones;
        </p>

        <p>
          To respond to your inquiries related to employment opportunities with
          us, or other general inquiries.
        </p>

        <h4>Legal basis for processing in the EU and the United Kingdom</h4>

        <p>
          If you are resident in the EU or the United Kingdom, we need to inform
          you about the legal basis on which we collect and use your personal
          information. In the EU and the United Kingdom, the purposes for which
          we process your personal information are:
        </p>

        <p>
          Where we need to perform the contract we are about to enter into or
          have entered into with you for the Service;
        </p>

        <p>
          For the purposes of legitimate interests (or those of a third party)
          and your interests and fundamental rights do not override those
          interests;
        </p>

        <p>
          Where we need to comply with a legal or regulatory obligation in the
          EU and the United Kingdom.
        </p>

        <p>
          The legal basis depends on the category of personal information being
          processed, and the purpose for that processing. The following table
          indicates each category of personal information we process, and the
          legal bases we rely on to do so. Where legitimate interest has been
          used as the legal basis for processing, the specific legitimate
          interest we use has been described.
        </p>

        <p>
          Please contact us if you need details about the specific legal basis
          we are relying on to process your personal information where one or
          more legal bases has been indicated.
        </p>

        <h4>When do we share personal information?</h4>

        <p>
          Except as described in this Privacy Policy, we will not disclose your
          personal information that we collect on the Service to third parties
          without your consent. We may disclose information to third parties if
          you consent to us doing so, as well as in the following circumstances:
        </p>

        <p>
          Service Providers. We may disclose personal information to third-party
          service providers (e.g., web hosting providers and other SaaS
          providers) that assist us in our work. We limit the personal
          information provided to these service providers to that which is
          reasonably necessary for them to perform their functions, and we
          require them to agree to maintain the confidentiality of such personal
          information.
        </p>

        <p>
          Business Transfers. Information about our users, including personal
          information, may be disclosed and otherwise transferred to an
          acquirer, successor or assignee as part of any merger, acquisition,
          debt financing, sale of company assets, or similar transaction, as
          well as in the event of an insolvency, bankruptcy, or receivership in
          which personal information is transferred to one or more third parties
          as one of our business assets.
        </p>

        <p>
          To Protect our Interests. We also disclose personal information if we
          believe that doing so is legally required, or is in our interest to
          protect our property or other legal rights (including, but not limited
          to, enforcement of our agreements), or the rights or property of
          others, or otherwise to help protect the safety or security of our
          Service and other users of the Service.
        </p>

        <p>
          To Comply with the Law: We may also disclose personal information in
          response to lawful requests by public authorities, including to meet
          national security or law enforcement requirements.
        </p>

        <h3>Communication Choices</h3>

        <p>
          If you receive marketing emails from us, you may unsubscribe at any
          time by following the instructions contained within the email. You may
          also opt-out from receiving marketing emails from us, and any other
          promotional communications that we may send to you from time to time
          (e.g., by postal mail) by sending your request to us by email at
          support@ecomtrack.io or by writing to us at the address given in the
          ‘Contacting Us’ section of this Privacy Policy.
        </p>

        <p>
          Please be aware that if you opt-out of receiving marketing email from
          us, it may take up to ten business days for us to process your opt-out
          request, and you may receive marketing email from us during that
          period. Additionally, even after you opt-out from receiving marketing
          messages from us, you will continue to receive administrative and
          transactional messages from us regarding your use of the Service.
        </p>

        <p>
          Through your use of the Services you may have provided us with a
          mobile telephone number. We may use this telephone number to send
          automated text/SMS messages, for example to send you reminders for
          various events, webinars and strategy sessions you have signed up for.
          You may opt-out of receiving these messages by replying with a request
          to opt-out. You may also opt-out of receiving these messages by
          sending your request to us by email at support@ecomtrack.io or by
          writing to us at the address given in the ‘Contacting Us’ section of
          this Privacy Policy.
        </p>

        <h4>Rights to access</h4>

        <p>
          If you have a user account and profile on our Service, you have the
          ability to access and update many categories of personal information
          that you provide to us by logging in to your account and accessing
          your account settings. If you wish to access or amend any other
          personal information we hold about you, you may contact us at
          support@ecomtrack.io. If you request that we delete your account with
          our Service, we will do so within a reasonable period of time, but we
          may need to retain some of your personal information in order to
          satisfy our legal obligations, or where we reasonably believe that we
          have a legitimate reason to do so.
        </p>

        <h4>Links to external sites</h4>

        <p>
          The Service may contain links to other websites, products, or services
          that we do not own or operate. The Service also may contain links to
          Third-Party Sites such as social networking services. If you choose to
          visit or use any Third-Party Sites or products or services available
          on or through such Third-Party Sites, please be aware that this Policy
          will not apply to your activities or any information you disclose
          while using those Third-Party Sites or any products or services
          available on or through such Third-Party Sites. We are not responsible
          for the privacy practices of these Third-Party Sites or any products
          or services on or through them. Additionally, please be aware that the
          Service may contain links to websites and services that we operate but
          that are governed by different privacy policies. We encourage you to
          carefully review the privacy policies applicable to any website or
          service you visit other than the Service before providing any personal
          information on them.
        </p>

        <h4>How long do we keep your personal information for?</h4>

        <p>
          Unless otherwise specifically stated elsewhere in this Privacy Policy,
          we will retain your personal information for the period necessary to
          fulfill the purposes outlined in this Privacy Policy, unless a longer
          retention period is required or permitted by law.
        </p>

        <p>
          Aggregated and anonymized data that no longer identifies the user of
          the Service is maintained for the purposes necessary to provide the
          Service.
        </p>

        <h4>EU privacy rights</h4>

        <p>
          If you are located in the EU, you have the following Data Subject
          Access Rights with respect to your personal information that we hold:
        </p>

        <p>
          Right of access. You have the right to access the personal information
          that we hold about you;
        </p>

        <p>
          Right to rectification. You may have the right to require us to
          correct any inaccurate or incomplete personal information we hold
          about you;
        </p>

        <p>
          Right to erasure. In certain circumstances, you may have the right to
          the erasure of your personal information we hold about you (for
          example where it is no longer necessary in relation to the purposes
          for which it was collected or processed);
        </p>

        <p>
          Right to restriction. You may have the right to request that we
          restrict processing of your personal information in certain
          circumstances (for example where the accuracy of the personal
          information is contested by you, for a period enabling us to verify
          the accuracy of that personal information);
        </p>

        <p>
          Right to portability. In some limited circumstances, you may have the
          right to portability which allows you to move, copy or transfer
          personal information from one organization to another;
        </p>

        <p>
          Right to object. You have a right to object to us processing your
          personal information when the processing is based on legitimate
          interests and also to stop us from sending you direct marketing;
        </p>

        <p>
          Rights in relation to automated decision making and profiling. You
          have the right not to be subject to a decision that affects you based
          solely on automated processing. We do not perform any automated
          decision making and profiling.
        </p>

        <p>
          If you wish to exercise one of these rights, please contact us using
          the information in the ‘Contacting us’ section of this Privacy Policy.
        </p>

        <h4>What is our policy on children?</h4>

        <p>
          Children’s safety is important to us, and we encourage parents and
          guardians to take an active interest in the online activities of their
          children. Our Services are not directed to users under the age of 16,
          and we do not knowingly collect personal information from children
          under the age of 16 without obtaining parental consent. If we learn
          that we have collected personal information from a child under the age
          of 16 on our Services, we will delete that information as quickly as
          possible. If you believe that we may have collected any such personal
          information on our Services, please notify us at support@ecomtrack.io.
        </p>

        <h4>Where do we store and process your personal information?</h4>

        <p>
          International Transfers: Our servers and data centers are located in
          the United States (US). If you choose to use the Service from outside
          the US, then you should know that you are transferring your personal
          information outside of your region and into the U.S. for storage and
          processing. We may also transfer your data from the U.S. to other
          countries or regions in connection with storage and processing of
          data, fulfilling your requests, and operating the Service. You should
          know that each region can have its own privacy and data security laws,
          some of which may be less stringent as compared to those of your own
          region. If you are located in the European Union (EU) or the United
          Kingdom (UK), then the countries we may transfer your data to,
          including the US, may not have data protection laws as comprehensive
          as those in the EU and the UK. To ensure your data is protected, and
          that we comply with the applicable data protection laws, we have
          implemented the following measures:
        </p>

        <p>
          Standard Contractual Clauses. We use the Standard Contractual Clauses
          (SCCs) for transfers of personal information to us, and also for
          transfer of personal information to third-party service providers.
          These clauses require the recipients to protect the personal
          information they receive in accordance with European data protection
          laws and regulations. Details of our use of SCCs can be provided upon
          request.
        </p>

        <p>
          Derogations. In certain circumstances we may transfer personal
          information based on the decorations contained in Article 49 of the
          General Data Protection Regulation (GDPR).
        </p>

        <p>
          Supplementary Messages. In addition to the SCCs, we may use
          contractual, technical and organizational measures to further protect
          your personal information.
        </p>

        <p>
          Adequacy Decisions. Where applicable, we may rely on adequacy
          decisions provided by the European Commission under Article 45 of the
          GDPR to transfer your personal information outside of the EU or UK.
        </p>

        <p>
          Nota that we no longer rely on the EU-US Privacy Shield Framework or
          the Swiss-US Privacy Shield Framework for the purposes of transferring
          data outside of the EU and United Kingdom, however we remain certified
          and committed to the Privacy Shield Framework principles, and this
          commitment is described in the next section. By providing your
          personal information to us through your use of the Services, you agree
          to the various measures we have implemented.
        </p>

        <p>
          Privacy Shield Notice: We comply with the EU-U.S. Privacy Shield
          Framework and Swiss-U.S. Privacy Shield Framework as set forth by the
          U.S. Department of Commerce regarding the collection, use, and
          retention of personal information received from European Union
          countries, the United Kingdom, and Switzerland (the "Privacy Shield").
          We have certified that we adhere to the Privacy Principles of notice,
          choice, and accountability for onward transfer, security, data
          integrity, purpose limitation, access, and recourse, enforcement, and
          liability ("Principles"). If there is any conflict between the
          policies in this Privacy Policy and the Privacy Shield Principles, the
          Principles shall govern. In cases of onward transfers of data received
          pursuant to the EU-U.S. and Swiss-U.S. Privacy Shield, we are
          potentially liable. To learn more about Privacy Shield, please visit
          the U.S. Department of Commerce Privacy Shield website:
          https://www.privacyshield.gov/. For more information regarding our
          Privacy Shield certification, please see:
          https://www.privacyshield.gov/list.
        </p>

        <h4>California privacy disclosures</h4>

        <p>
          California law requires us to let you know how we respond to web
          browser Do Not Track (DNT) signals. Because there currently isn’t an
          industry or legal standard for recognizing or honoring DNT signals, we
          don’t respond to them at this time. We await the result of work by the
          privacy community and industry to determine when such a response is
          appropriate and what form it should take.
        </p>

        <p>
          A California resident who has provided personal information to a
          business with whom he/she has established a business relationship for
          personal, family, or household purposes (“California Customer”) is
          entitled to request information about whether the business has
          disclosed personal information to any third parties for the third
          parties’ direct marketing purposes, subject to certain exceptions, as
          defined in California Civil Code Sec. 1798.83. In general, subject to
          certain exceptions, if the business has made such a disclosure of
          personal information, upon receipt of a request by a California
          Customer, the business is required to provide, free of charge, a list
          of all third parties to whom personal information was disclosed in the
          preceding calendar year, as well as a list of the categories of
          personal information that were disclosed. California Customers may
          request further information about our compliance with this law by
          sending an email to.
        </p>

        <h4>How do we secure your personal information?</h4>

        <p>
          To help protect your data, we use commercially reasonable steps to
          protect the data that we collect, including your personal information.
          The reasonable steps include protecting this data against accidental
          loss, unauthorized use, disclosure, and restricting access to personal
          information by our staff. The Service is hosted by a third-party
          hosting company that we have determined maintains adequate security
          controls and utilizes TLS encryption for all internet communication
          with the Service. We also require all staff that administer and
          develop the Service follow industry-standard controls, including
          strong passwords, the use of anti-virus and anti-malware software,
          disk encryption and other best practices.
        </p>

        <p>
          We use various 3rd party processors to enable us to provide the
          Service, and as part of our vendor due-diligence, we review the
          security controls these processors have in place and ensure they meet
          industry standards appropriate for the type of data we collect.
        </p>

        <p>
          You should keep in mind, however, that the Service utilizes software,
          hardware, and networks, which from time to time require maintenance
          and experience problems beyond our control. Note that no data
          transmission over the public internet or encryption method can be
          guaranteed to be 100% secure. Consequently, we cannot ensure or
          warrant the security of any information that you provide to us. You
          transmit information to us at your own risk.
        </p>

        <h4>Updates to this Policy</h4>

        <p>
          We may occasionally update this Policy. When we do, we will also
          revise the ‘last updated’ date at the beginning of the Policy. Your
          continued use of our Service after such changes will be subject to the
          then-current policy. If we change this Policy in a manner that is
          material, we will use reasonable efforts to notify you via the contact
          methods you have provided of the change prior to applying the change
          to any personal information that we collected from you prior to the
          date the change becomes effective. We encourage you to periodically
          review this Privacy Policy to stay informed about how we collect, use,
          and disclose personal information.
        </p>
      </Col>
    </Section>

    <Footer colFlex></Footer>
  </main>
);

export default PrivacyPolicy;
